<template>
  <div
    class="PasswordCriteria">
    <div
      class="__label">
      {{ $t('shared.passwordRequirementsLabel') }}
    </div>
    <div
      v-for="requirement in passwordCriteria"
      class="__requirement"
      :class="{ isSatisfied: requirement.isSatisfied }"
      :key="requirement.label">
      <div
        class="__icon">
        <IconCheckmark
          class="__checkmark"
          :size="16"
          :is-checked="requirement.isSatisfied"
        />
      </div>
      {{ requirement.label }}
    </div>
  </div>
</template>


<script>
import usePassword from '@/use/password';
import IconCheckmark from '@/components/IconCheckmark.vue';

export default {
  name: 'PasswordCriteria',

  components: {
    IconCheckmark,
  },

  setup() {
    const {
      validatePassword,
    } = usePassword();

    return {
      validatePassword,
    };
  },

  props: {
    password: {
      type: String,
      required: true,
    },
  },

  computed: {
    passwordCriteria() {
      const { criteria } = this.validatePassword(this.password);
      return criteria;
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables';

.PasswordCriteria {
  position: relative;
  font-size: 12px;
  color: $mute;
}

.__label {
  padding-bottom: 4px;
  font-weight: $font-weight-semi-bold;
}

.__icon {
  $icon-size: 14px;
  flex: 0 0 $icon-size;
  width: $icon-size;
  height: $icon-size;
  margin-right: 6px;
  border: 1px solid $mute;
  border-radius: 50%;
  .__checkmark {
    transform: translate(-1.5px, -1.5px) scale(0.75);
  }
}

.__requirement {
  display: flex;
  align-items: center;
}

.__requirement.isSatisfied {
  $satisfied-color: $light-blue;
  // color: $satisfied-color;
  text-decoration: line-through;
  .__icon {
    color: $white;
    background-color: $satisfied-color;
    border-color: $satisfied-color;
  }
}
</style>
