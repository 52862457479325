<template>
  <div class="ProfileView">
    <HeaderStep
      :logo="flow?.headerLogo"
      :theme="currentStep.headerTheme"
      :title="$t('flows.shared.steps.profile.title')"
      :subtitle="$t('flows.shared.steps.profile.subtitle')"
      :progress-label="$t('flows.shared.steps.profile.progressLabel')"
      :stepper-steps="stepperSteps"
      :active-stepper-step-id="currentStep.id"
    />

    <div class="container">
      <form
        @submit.prevent="handleSubmit">

        <div
          class="__double">
          <InputText
            class="__field"
            :placeholder="$t('shared.firstName')"
            :is-placeholder-persistent="true"
            v-model="session.firstName.value"
            :is-invalid="hasSubmitted && !session.firstName.isValidClientSide"
            :error-message="$t('shared.firstNameIsRequired')"
            ref="inputFirstName"
          />

          <span
            class="__spacer">
          </span>

          <InputText
            class="__field"
            :placeholder="$t('shared.lastName')"
            :is-placeholder-persistent="true"
            v-model="session.lastName.value"
            :is-invalid="hasSubmitted && !session.lastName.isValidClientSide"
            :error-message="$t('shared.lastNameIsRequired')"
          />
        </div>

        <InputText
          v-if="session.phone"
          class="mt-2"
          type="tel"
          :placeholder="$t('shared.phone')"
          :is-placeholder-persistent="true"
          v-model="session.phone.value"
          :is-invalid="hasSubmitted && !session.phone.isValidClientSide"
          :error-message="$t('shared.pleaseEnterValidPhoneNumber')"
          key="profile-phone"
        />

        <InputText
          v-if="session.company"
          class="mt-2"
          key="company-name-field"
          :placeholder="$t('shared.company')"
          :is-placeholder-persistent="true"
          v-model="session.company.value"
          :is-invalid="hasSubmitted && !session.company.isValidClientSide"
          :error-message="$t('shared.companyNameIsRequired')"
        />

        <InputText
          class="mt-2"
          type="password"
          :placeholder="$t('shared.password')"
          :is-placeholder-persistent="true"
          v-model="session.password.value"
          :is-invalid="hasSubmitted && !session.password.isValidClientSide"
          :error-message="computedPasswordErrorMessage"
          @keydown.prevent.space
          @focus="isPasswordFocused = true"
          @blur="isPasswordFocused = false"
        />

        <PasswordCriteria
          class="mt-1"
          v-show="isPasswordFocused && !session.password.isValidClientSide"
          :password="session.password.value"
        />

        <div
          class="mt-2"
          v-show="session.password.isValidClientSide">
          <InputSelect
            v-model="session.country.value"
            :placeholder="$t('shared.country')"
            :is-searchable="true"
            :is-placeholder-persistent="true"
            :options="$i18n.messages[$i18n.locale].countries"
            :is-invalid="hasSubmitted && !session.country.isValidClientSide"
            :search-placeholder="$t('shared.search')"
          />

          <InputCheckbox
            class="mt-2"
            v-if="!isAutoConsentCountry"
            v-model="session.consent.value"
            key="profile-consent">
            {{ $t('legal.requestConsent') }}
            <span
              v-html="$t('legal.managePreferences')">
            </span>
          </InputCheckbox>

          <p
            v-if="isAutoConsentCountry"
            class="__fineprint mt-2"
            key="profile-auto-consent">
            {{ $t('legal.autoConsent') }}
          </p>

          <InputCheckbox
            v-if="session.agreeToTerms"
            class="mt-3"
            key="terms"
            v-model="session.agreeToTerms.value"
            :is-invalid="hasSubmitted && !session.agreeToTerms.isValidClientSide">
            <span
              v-html="$t('legal.agreeToTerms')">
            </span>
          </InputCheckbox>
        </div>

        <ErrorTile
          class="mt-3"
          v-show="hasSubmitted && !isCurrentStepValidClientSide">
          {{ $t('shared.pleaseCompleteFields') }}
        </ErrorTile>

        <div
          class="text-center mt-4">
          <ButtonBase
            class="uppercase"
            type="submit"
            :is-loading="isSubmitting">
            {{ $t('ctas.next') }}
          </ButtonBase>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
import usePassword from '@/use/password';
import HeaderStep from '@/components/HeaderStep.vue';
import InputText from '@/components/InputText.vue';
import InputSelect from '@/components/InputSelect.vue';
import InputCheckbox from '@/components/InputCheckbox.vue';
import PasswordCriteria from '@/components/PasswordCriteria.vue';
import ErrorTile from '@/components/ErrorTile.vue';
import ButtonBase from '@/components/ButtonBase.vue';

export default {
  name: 'ProfileView',

  components: {
    HeaderStep,
    InputText,
    InputSelect,
    InputCheckbox,
    PasswordCriteria,
    ErrorTile,
    ButtonBase,
  },

  inject: [
    'domo',
    'flow',
    'session',
    'saveSession',
    'nextStep',
    'currentStep',
    'stepperSteps',
    'isCurrentStepValidClientSide',
    'trackEvent',
    'amplitude',
  ],

  setup() {
    const {
      validatePassword,
    } = usePassword();

    return {
      validatePassword,
    };
  },

  data() {
    return {
      isSubmitting: false,
      hasSubmitted: false,
      isPasswordFocused: false,
    };
  },

  computed: {
    computedPasswordErrorMessage() {
      const { errorMessage } = this.validatePassword(this.session.password.value);
      return `* ${errorMessage}`;
    },

    isAutoConsentCountry() {
      return ['US'].includes(this.session.country.value);
    },
  },

  mounted() {
    setTimeout(() => this.$refs.inputFirstName.focus(), 100);
  },

  methods: {
    async handleSubmit() {
      this.hasSubmitted = true;

      if (this.isSubmitting || !this.isCurrentStepValidClientSide) return;

      this.isSubmitting = true;

      this.trackEvent('submitted profile info', this.currentStep, this.session);

      this.saveSession();

      try {
        if (process.env.NODE_ENV !== 'development') {
          await this.$http.post('/send/v2/dragonglass', {
            affiliateCode: this.session.affiliateCode ?? null,
            amplitudeDeviceId: this.amplitude.deviceId,
            amplitudeUserId: this.amplitude.userId,
            appstore: this.session?.appstore ?? null,
            company: this.session.company?.value ?? null,
            consent: this.session.consent?.value ?? null,
            country: this.session.country.value,
            email: this.session.email.value,
            first_name: this.session.firstName.value,
            lang: this.domo.lang,
            last_name: this.session.lastName.value,
            password: this.session.password.value,
            pathName: window.location.pathname,
            uniqueFormId: this.domo.uffid,
          });
        }

        this.$router.push({
          name: 'step',
          params: {
            lang: this.$route.params.lang || '',
            flowName: this.$route.params.flowName,
            stepName: this.nextStep.routeName,
          },
          query: this.$route.query,
        });
      } catch (error) {
        this.isSubmitting = false;

        // @TODO show some kind of UI error

        console.error('ERROR CREATING INSTANCE: ', error);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables';

.ProfileView {
  padding-bottom: 80px;
}

.__spacer {
  display: none;
}

.__field {
  margin-top: 16px;
}

@include bp-iPhonePlus {
  .__double {
    display: flex;
  }

  .__spacer {
    display: block;
    padding: 8px;
  }

  .__field {
    flex: 1 1 50%;
  }
}

.__fineprint {
  font-size: 12px;
  color: $mute;
}
</style>
