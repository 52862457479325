<template>
  <div
    class="InputCheckbox"
    :class="{
      isDisabled,
      isChecked: modelValue,
      isInvalid,
    }"
    tabindex="0"
    ref="checkbox"
    @keyup.enter.space="handleCheck"
    @click="handleCheck"
    @focus="handleFocus">

    <div
      class="__box">
      <svg
        class="__svg"
        viewBox="0 0 16 16"
        key="BC-checkmark">
        <path
          class="__checkmark"
          d="M3.75 8.5 6.5 12 12.25 3.75"
        />
      </svg>
    </div>

    <div
      class="__label">
      <slot/>
    </div>
  </div>
</template>


<script>
export default {
  name: 'InputCheckbox',

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Label',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'update:modelValue',
    'focus',
  ],

  methods: {
    handleCheck(e) {
      const isEventFromAnchor = e.target && e.target.tagName === 'A';

      if (this.isDisabled || isEventFromAnchor) return;

      this.$emit('update:modelValue', !this.modelValue);
    },

    handleFocus(e) {
      this.$emit('focus', e);
    },

    focus() {
      this.$refs.checkbox.focus();
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables';

$box-size: 18px;

.InputCheckbox {
  display: inline-block;
  position: relative;
  text-align: left;
  white-space: nowrap;
  border-radius: $radius;
  outline: none;
}

.InputCheckbox:not(.isDisabled) {
  cursor: pointer;
}

.__box {
  display: inline-block;
  position: relative;
  width: $box-size;
  height: $box-size;
  vertical-align: text-top;
  border-width: 1px;
  border-style: solid;
  border-color: $border-color;
  border-radius: $radius;
  background-color: $white;
  transition: all .2s ease 0s;
}

.InputCheckbox:hover .__box,
.InputCheckbox:focus .__box {
  border-color: $dark;
}

.__label {
  display: inline-block;
  margin-left: -$box-size;
  padding-left: $box-size + 8px;
  padding-right: $box-size;
  vertical-align: text-top;
  font-size: 14px;
  line-height: $box-size;
  white-space: normal;
}

.__svg {
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  width: $box-size;
  height: $box-size;
}

.__checkmark {
  fill: none;
  stroke: $white;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: $box-size;
  stroke-dashoffset: $box-size;
  transition: all .3s ease 0s;
}

.isChecked {
  .__box {
    background: $dark;
    border-color: $dark;
  }
  .__checkmark {
    stroke-dashoffset: 0;
  }
}

.isInvalid {
  .__box {
    border-color: $red !important;
  }
}
</style>
