<template>
  <div
    class="ErrorTile">
    <ErrorMessage>
      <slot/>
    </ErrorMessage>
  </div>
</template>


<script>
import ErrorMessage from '@/components/ErrorMessage.vue';

export default {
  name: 'ErrorTile',

  components: {
    ErrorMessage,
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.ErrorTile {
  padding: 16px;
  border: 1px solid $red;
  border-radius: $radius;
  background: rgba($red, 0.07);
}
</style>
